import Model from './_base';

export default class ByerInfo extends Model {
    static entity = 'buyer-info';
    
    constructor() {
        super(ByerInfo.entity);
    }
    
    static getBuyerInfo(companyCode){
      return Model.axios.get(this.url('?companyCode='+companyCode));
    }
    
}
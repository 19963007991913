<template>
  <div>
    <ul class="biz-breadcrumb" v-if="!isRegistrationWizard">
      <li><router-link to="/supplier/manage">{{$t('SYSTEM_MANAGEMENT.TITLE')}}</router-link></li>
      <li><router-link to="/supplier/manage/buyer-info">{{$t('BUYER_INFO.TITLE')}}</router-link></li>
      <li><a>{{$t('BUYER_INFO.TITLE_ADD')}}</a></li>
    </ul>
     <div class="page">
      <div class="page-title">
        <h1>{{$t('BUYER_INFO.TITLE_ADD')}}</h1>
        <p>注意事項をお読みになった上で取引を希望されるバイヤをお選びください。</p>
      </div>
      <div class="warning-box p-4 pb-2 mt-4">
        <p class="font-weight-bold">
          【重要】取引を行うために必要な契約の締結について
        </p>
        <pre>
ビズハイウェイにご参加いただくためには、事前に共通取引基本契約書に同意していただく必要があります。
なお、共通取引基本契約書以外の特約条項があるバイヤ企業には「特約あり」のマークを表示しております。
取引希望のバイヤ企業をご選択していただいたのちに、バイヤ企業の審査があります。
バイヤ企業の審査が合格した場合のみ、当該バイヤ企業の特約条項をご確認いただけます。バイヤ企業の審査は約2週間かかります。
共通取引基本契約書及び特約条項は電子契約になっており、電子証明書を利用した電子署名をしていただきますので、
署名者の登録申込書を提出頂き署名者の登録をいただく必要があります。</pre>
      </div>
      <div class="card biz-primary mt-4">
        <div class="card-header">
          取引を希望するバイヤ企業の選択
          <p>取引を希望されるバイヤ企業を選んで、「次へ」ボタンを押してください。</p>
        </div>
        <div class="card-body">
          <div class="p-2 bg-white">
            <table class="table table-bordered">
              <colgroup>
                <col span="1" style="width: 5%;">
                <col span="1" style="width: 5%;">
                <col span="1" style="width: 20%;">
                <col span="1" style="width: 30%;">
                <col span="1" style="width: 30%;">
              </colgroup>
              
              <thead>
                <tr>
                  <th class="text-center">選択</th>
                  <th class="text-center">No.</th>
                  <th class="text-center">バイヤ企業名</th>
                  <th class="text-center">本社所在地</th>
                  <th class="text-center">業種</th>
                </tr>
              </thead>
              <tbody>
                <tr v-bind:key="buyer.id" v-for="(buyer, index) in buyers">
                  <td class="text-center">            
                    <b-checkbox :disabled="buyer.owner" v-model="buyer.selected"></b-checkbox>
                  </td>
                  <td class="text-center">{{index + 1 || 'No'}}</td>
                  <td>{{buyer.buyerCompanyGroupName || '≪未登録≫'}}</td>
                  <td><pre style="font-family:inherit;">{{buyer.buyerAddress || '≪未登録≫'}}</pre></td>
                  <td><pre style="font-family:inherit;">{{buyer.buyerIndustry || '≪未登録≫'}}</pre></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="text-center mt-5">
        <div class="text-center mt-5">
          <j-button variant="primary" size="lg" v-on:click="onSave">
            {{isRegistrationWizard?'次へ':'保存'}}
          </j-button>
          <div class="mt-4" v-if="!isRegistrationWizard">
            <j-button variant="light" to="/supplier/manage/buyer-info">
              <font-awesome-icon icon="arrow-left"></font-awesome-icon>
              前へ戻る
            </j-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
<script>
  import BuyerInfo from '@/model/BuyerInfo';
  import CompanyInfo from '@/model/CompanyInfo';
  
  export default {
    props: ["isRegistrationWizard"],
    components: {  },
    data: () => ({
      buyers: [{}]
    }),
    mounted: function(){
      Promise.all([
        BuyerInfo.getBuyerInfo(this.$store.state.user.companyCode)
      ])
      .then(results => {
        this.buyers = results[0].data;
      });
    },
    methods:{
      onSave(){
        CompanyInfo.saveCompanyBuyer( this.$store.state.user.companyCode, this.buyers.map(b => {
          return {
            buyerCompanyGroupCode: b.buyerCompanyGroupCode,
            applied: b.selected
          }
        })).then(() => {
          CompanyInfo.changeStatus( this.$store.state.user.companyCode, {
            statusName: 'IS_BUYER_REGISTERED',
            status: true
          }).then(() => {
            this.$emit('onSave', 'BuyerInfo');
          }).catch(reason => this.$errorMessage(undefined, {reason}));
        }).catch(reason => this.$errorMessage(undefined, {reason}));
      }
    }
  }
</script>

